
import { defineComponent } from "vue";
import { deleteAllData } from "@/services/AuthenticationService";
import { TYPE, useToast } from "vue-toastification";
import { updateAuthorizationHeader } from "@/services/Api";
export default defineComponent({
  data() {
    return {
      loading: false as boolean,
    };
  },
  computed: {
    disableDeleteButton() {
      return !this.$store.state.userFirebaseUid;
    },
  },
  methods: {
    async deleteData() {
      this.loading = true;
      const response = await deleteAllData(this.$store.state.userFirebaseUid);
      if (response.status === 200) {
        updateAuthorizationHeader(null);
        this.$store.dispatch("setUserAlias", null);
        this.$store.dispatch("setUserFirebaseUid", null);
        const toast = useToast();
        toast("Your data was successfully deleted. You will be redirected...", {
          timeout: 5000,
          type: TYPE.SUCCESS,
          hideProgressBar: true,
        });
        // stalls for three seconds, then redirects to the home page
        await new Promise((resolve) => setTimeout(resolve, 3000));
        this.$router.push({ name: "Home" });
      } else {
        const toast = useToast();
        toast("Failed to delete user data. Please try again later.", {
          timeout: 5000,
          type: TYPE.ERROR,
          hideProgressBar: true,
        });
      }
      this.loading = false;
    },
  },
});
